@import '~antd/dist/antd.min.css';
@import url('https://fonts.googleapis.com/css?family=Varela+Round');

#root {
  height: 100%;
}

/* html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Varela Round', sans-serif;
} */
