body {
  background-color: #efefef;
}

.ant-table-row {
  cursor: pointer;
}

.app {
  height: 100%;
}

.app-content {
  padding: 10px;
}

.app-navbar {
  margin-bottom: 10px;
}

.app-navbar-heading {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Varela Round', sans-serif;
}

.page {
  margin-left: 10px;
}

.container-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.page-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f0f0f0;
}

.page-header {
  background-color: #fff;
  margin-bottom: 15px;
}

.page-body {
  background-color: #fff;
}

.page-header-inner {
  height: 45px;
  display: flex;
  align-items: center;
}

.page-header-title {
  flex-grow: 2;
  font-family: 'Varela Round', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-header-title h2 {
  /*margin-bottom: 0px;*/
  max-height: 90px;
  overflow: hidden;
}

.page-header-content {
  display: flex;
}

.section-header {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  background-color: #ddd;
  padding: 6px 10px 6px 10px;
  font-family: 'Varela Round', sans-serif;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader-message {
  color: #333;
}

.loader-text {
  font-size: 40px;
  font-weight: bold;
  margin-left: 10px;
  font-family: 'Varela Round', sans-serif;
}

.loader-small {
  transform: scale(0.5, 0.5);
}

.page-signin h3,
p {
  text-align: center;
  font-family: 'Varela Round', sans-serif;
}

.signin-box {
  width: 350px;
  border: #d9d9d9 1px solid;
  border-radius: 20px;
  padding: 15px;
  background-color: white;
}

.signin-box img {
  width: 100%;
}

.page-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  word-break: break-all;
}

.page-main {
  padding-right: 10px;
  width: 70%;
}

.page-sidebar {
  width: 28%;
  border-left: 1px dashed #ddd;
  padding: 10px;
  height: 100%;
}

.page-survey-sidebar {
  width: 30%;
  border-left: 1px dashed #ddd;
  padding: 10px;
  height: 100%;
}

.react-grid-Grid {
  border: none !important;
}

.react-grid-HeaderCell {
  font-weight: normal !important;
  color: #333 !important;
}

.layout-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-panel {
  padding: 15px;
  background: #efefef;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.form-panel-title {
  font-size: 10px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #666;
  font-family: 'Varela Round', sans-serif;
  text-shadow: 1px 1px #fff;
}

.form-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-spacing-right {
  margin-right: 10px;
}

.form-spacing-below {
  margin-bottom: 10px;
}

.form-column-fill {
  flex-grow: 2;
}

.form-padding {
  padding: 10px 0 10px 0;
}

.form-padding-2 {
  padding: 10px 10px 10px 10px;
}

.form-element-width {
  width: 200px;
}

.form-element-row-icon {
  margin-right: 10px;
}

.form-element-row-button {
  margin-left: 10px;
}

.react-grid-Row {
  cursor: pointer;
}

.participant-dialog {
  width: 600px;
}

.invite-row {
  margin-bottom: 10px;
}

.invite-row .participant-id {
  font-family: 'Courier New';
}

.dialog-row {
  margin-bottom: 10px;
}

.inline-spinner {
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: 'Varela Round', sans-serif;
}

.info-label {
  font-size: 11px;
  color: #999;
  text-align: center;
}

.list-row {
  margin: 5px 0 10px 0;
  font-size: 12px;
  border-radius: 5px;
  border: 1px dashed #bbb;
}

.list-row-empty {
  padding: 20px;
  color: #999;
  font-style: italic;
  text-align: center;
}

label {
  margin-bottom: 10px;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-form-item-control {
  line-height: 20px;
}

.ant-upload-list-item {
  /*margin-top: 0px;*/
}

.footer-menu {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.participant-chart .ant-tooltip-inner {
  color: black;
  background-color: transparent;
}

.participant-chart .ant-tooltip-arrow {
  border-color: transparent;
}

.participant-chart-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: center;
}

.program-responses-menu {
  text-align: right;
  margin-bottom: 10px;
}

.program-responses-menu > * {
  display: inline-block;
}

.program-variables-csv-instruction {
  max-width: 600px;
  float: left;
  text-align: left;
  margin-bottom: 10px;
}

.program-variables-menu {
  text-align: right;
  margin-bottom: 10px;
}

.program-variables-menu > * {
  display: inline-block;
}

.participant-button:hover {
  padding-right: 25px;
}

.setting-danger-button {
  background-color: white;
  color: black;
  border: 1px solid #f44336;
}

.setting-danger-button:hover {
  background-color: #f44336;
  color: white;
}

.admins-danger-button {
  background-color: white;
  color: black;
  border: 1px;
}

.admins-danger-button:hover {
  background-color: #f44336;
  color: white;
}
